import React from "react"
import Layout from "../components/Layout"
import "../css/projects/pythonprojectcard.css"
import { Link } from "gatsby"
import Particle7 from "../components/Particle7"
import SEO from "../components/SEO"

const Pythonprojectcard = () => {
    return (
        <Layout>
            <SEO
                title="Python Projects"
                description="This Page is for Python Project Ocean."
            />
            <Particle7></Particle7>
            <h6>s</h6>
            <div className="programming-projectp">
                <h1>Data Analysis and Visulization</h1>
            </div>

            <div className="favp noSelect">
                <h3>
                    Those projects having a{" "}
                    <span role="img" aria-label="Heart">
                        ❤️
                    </span>{" "}
                    on them... come under the Favourite category.{" "}
                </h3>
            </div>

            <section className="most-likedp noSelect">
                <h3 className="totalp">Total Projects :- 2</h3>
                <div className="rowp">
                    <div className="rotatep">
                        <div className="coffee-cardp">
                            <div className="card-headerp">
                                <h3 className="title-textp">
                                    Emergency-911 Calls
                                    <span role="img" aria-label="Heart">
                                        ❤️
                                    </span>
                                </h3>
                            </div>
                            <div className="data-img1"></div>
                            <div className="backp">
                                <div className="back-contentp">
                                    <div className="layer"></div>
                                    <h4 className="textp">Checkout here...</h4>
                                    <div className="bttn-boxp">
                                        <a
                                            href="https://github.com/arpitsomani8/Data-Capstone-Projects/tree/master/Emergency%20-%20911%20Calls"
                                            className="btnttp"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Github Code
                                        </a>


                                        <Link to="/data_proj1/" className="btnttp">
                                            More Detail
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rotatep">
                        <div className="coffee-cardp">
                            <div className="card-headerp">
                                <h3 className="title-textp">
                                    Finance Data Project
                                    <span role="img" aria-label="Heart">
                                        ❤️
                                    </span>
                                </h3>
                            </div>
                            <div className="data-img2"></div>
                            <div className="backp">
                                <div className="back-contentp">
                                    <div className="layer"></div>
                                    <h4 className="textp">Checkout here...</h4>
                                    <div className="bttn-boxp">
                                        <a
                                            href="https://github.com/arpitsomani8/Data-Capstone-Projects/tree/master/Finance%20Data%20Project"
                                            className="btnttp"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Github Code
                                        </a>


                                        <Link to="/data_proj2/" className="btnttp">
                                            More Detail
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </Layout>
    )
}

export default Pythonprojectcard
